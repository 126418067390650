import { format } from "date-fns";

export const capitalizeJustFirst = (str: string) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const humanize = (str: any) => {
	if (str === "start_date") return "Data attivazione";
	if (str === "end_date") return "Data disattivazione";
	if (str === "created_at") return "Creata il";
	if (str === "updated_at") return "Aggiornata il";
	if (str === "hasBtnValue") return "Valore nel pulsante";
	if (str === "hasTooltip") return "Tooltip";
	if (str === "is_universal") return "Campo universale";
	if (str === "universalVal") return "Valore universale";
	if (str === "max_length") return "Lunghezza massima";
	if (str === "is_highlighted") return "Evidenziato";
	if (str === "active") return "Attivo";
	if (str === "enabled") return "Abilitato";
	if (str === "section") return "Sezione";

	let i;
	const frags = str.split("_");
	for (i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(" ");
};

export const humanizeValue = (fieldKey: string, value: any) => {
	const _key = `${fieldKey}`;

	if (typeof value !== "boolean" && !value) return "";

	switch (_key) {
		case "start_date":
		case "end_date":
		case "created_at":
		case "updated_at":
			return format(new Date(value), "dd/MM/yyyy HH:mm");

		case "active":
		case "is_admin":
		case "hasBtnValue":
		case "hasTooltip":
		case "is_universal":
			return value ? "Si" : "No";

		default:
			if (typeof value === "string") return capitalizeJustFirst(value.replaceAll("_", " "));
			if (typeof value === "number") return value;
			if (typeof value === "boolean") return value ? "Si" : "No";
			return value;
	}
};
