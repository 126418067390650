/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
import {
	CCard, CCardBody, CCol, CRow, CCardHeader, CButton,
} from "@coreui/react-pro";
import { format } from "date-fns";
import {
	memo, useCallback, useEffect, useState,
} from "react";
import { useParams } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import { useNotifications } from "reapop";
import AxiosCaller from "../../utils/AxiosCaller";
import BaseInput from "../../components/Input/BaseInput/BaseInput";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import AddField from "../Fields/AddField/AddField";
import useUserFields from "../../hooks/useUserFields";

const Applications = () => {
	const { notify } = useNotifications();
	const [visible, setVisible] = useState(false);
	// const { setToastNotification } = useContext(ToastContext);
	const [application, setApplication] = useState({
		campi_bando_valorizzati: [],
	} as any);
	const [loading, setLoading] = useState(false);
	const { applicationId: urlApplicationId } = useParams();
	const [editErrors, setEditErrors] = useState({} as any);
	const [campoToEdit, setCampoToEdit] = useState({} as any);
	const [indexCampoToEdit, setIndexCampoToEdit] = useState(-1);
	const [users, setUsers] = useState([]);
	const [isedit, setIsedit] = useState(!!urlApplicationId);
	const [applicationId, setApplicationId] = useState(urlApplicationId);

	const {
		fetchUsers: fetchUserFields,
		userFields,
		setUserFields,
	} = useUserFields({ editMode: false, bando_id: applicationId } as any);

	const fetchUsers = async () => {
		try {
			setLoading(true);
			const { data: _users } = (await AxiosCaller.get("/api/user/view", {
				params: {},
			})) as any;
			setUsers(_users);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchUsers();
		fetchUserFields(true);
	}, [fetchUserFields]);

	useEffect(() => {
		const fetchApplication = async () => {
			try {
				setLoading(true);
				const { data: _application } = (await AxiosCaller.get(`/api/bando/${applicationId}`, {
					params: {},
				})) as any;
				setApplication(_application);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		if (applicationId) fetchApplication();
	}, [applicationId]);

	const onNameUpdate = (_name: any) => {
		setApplication({
			...application,
			nome: _name,
		});
	};
	const onStartDate = (startDate: any) => {
		setApplication({
			...application,
			start_date: startDate,
		});
	};
	const onEndDate = (endDate: any) => {
		setApplication({
			...application,
			end_date: endDate,
		});
	};

	const onEdit = (index: number) => {
		if (index > -1) {
			setCampoToEdit(application.campi_bando[index]);
			setIndexCampoToEdit(index);
		}
		setVisible(true);
	};

	const closeModal = () => {
		setVisible(false);
		setCampoToEdit({} as any);
	};

	const addCampoBando = () => {
		setVisible(true);
	};

	const onEditCampoBando = (campoBando: any, index: number) => {
		if (index > -1) {
			const newCampiBando = [...application.campi_bando];
			newCampiBando[index] = campoBando;
			setApplication({
				...application,
				campi_bando: newCampiBando,
			});
			setVisible(false);
		}
	};
	const onAddCampoBando = (campoBando: any) => {
		setApplication({
			...application,
			campi_bando: [...(application.campi_bando ?? []), campoBando],
		});
		setVisible(false);
	};

	const onCampiBandoValuesChange = (user_id: any, nome: any, valore: any) => {
		const newCampiBandoValorizzati = [...(application.campi_bando_valorizzati ?? [])];

		const campoBandoValorizzato = newCampiBandoValorizzati.find(
			(campo_bando: any) => campo_bando.user_id === user_id && campo_bando.nome === nome,
		);

		if (campoBandoValorizzato) campoBandoValorizzato.valore = valore;
		else newCampiBandoValorizzati.push({ user_id, nome, valore });

		setApplication({
			...application,
			campi_bando_valorizzati: newCampiBandoValorizzati,
		});
	};

	const getCampiBandoUtente = () => application?.iscritti?.map((iscritto: any, indextolor: number) => {
		const index = users.findIndex((user: any) => user?.id === iscritto?.user_id);

		return (
			<div key={indextolor}>
				<span>Utente: {(users?.[index] as any)?.alias ?? (users?.[index] as any)?.p_iva}</span>
				{application?.campi_bando?.filter((_campo: any) => !_campo.universalVal && !_campo.section).map((_campo: any) => {
					let initialValue = "";
					const _index = application?.campi_bando_valorizzati?.findIndex(
						(campo_bando: any) => campo_bando?.nome === _campo?.nome && campo_bando?.user_id === iscritto?.user_id,
					);
					if (_index > -1) initialValue = application?.campi_bando_valorizzati?.[_index]?.valore;
					return (
						<BaseInput
							disabled={loading}
							error=""
							initialValue={initialValue}
							inputGroupClassName="mb-3"
							key={`${iscritto?.user_id}-${_campo?.nome}`}
							setValue={(val) => onCampiBandoValuesChange(iscritto?.user_id, _campo?.nome, val)}
							stringIcon={_campo?.nome}
						/>
					);
				})}
				{indextolor + 1 !== application?.iscritti.length && <hr />}
			</div>
		);
	});

	const onUsersChange = (values: any[]) => {
		const newIscritti: any[] = [];
		values.forEach(({ value }: any) => {
			const index = users.findIndex((user: any) => user?.id === value);
			if (index > -1) newIscritti.push({ bando_id: application?.id, user_id: (users?.[index] as any)?.id });
		});

		setApplication({
			...application,
			iscritti: newIscritti,
		});
	};

	const onDnd = (items: any[]) => {
		setApplication({
			...application,
			campi_bando: items.map((item: any) => ({
				...item,
				id: item.campo_id,
			})),
		});
	};

	const orderFields = useCallback(async (application_id = applicationId) => {
		try {
			await AxiosCaller.post("/api/bando/user/orderFields", {
				campi_utente: userFields.map((field: any) => field.id),
				bando_id: application_id,
			});
		} catch (e) {
			console.error(e);
		}
	}, [applicationId, userFields]);

	const onSave = async () => {
		try {
			setLoading(true);

			let _iscritti = [];
			let _campi_bando: any = [];
			let application_id = applicationId;

			const params = {
				nome: application?.nome,
				end_date: application?.end_date ? format(new Date(application?.end_date), "yyyy-MM-dd HH:mm:ss") : null,
				start_date: application?.start_date ? format(new Date(application?.start_date), "yyyy-MM-dd HH:mm:ss") : null,
				campi_bando: application?.campi_bando ?? [],
				users: application?.iscritti.map((_iscr: any) => _iscr.user_id),
			};

			if (isedit) {
				const {
					data: {
						bando: { id, iscritti, campi_bando },
					},
				} = (await AxiosCaller.post(`/api/bando/${applicationId}`, params)) as any;
				_iscritti = iscritti;
				_campi_bando = campi_bando;
				application_id = id;
			} else {
				const {
					data: {
						bando: { id, iscritti, campi_bando },
					},
				} = (await AxiosCaller.post("/api/bando", params)) as any;
				_iscritti = iscritti;
				_campi_bando = campi_bando;
				application_id = id;
				setApplicationId(id);
				setIsedit(true);
			}

			const promises = [];

			for (const iscritto of _iscritti) {
				const campiUser = application.campi_bando_valorizzati.filter(
					(_campo: any) => _campo.user_id === iscritto.user_id,
				);

				if (campiUser.length) {
					const _params = {
						bando_id: application_id,
						user_id: iscritto.user_id,
						campi_bando: campiUser
							.filter((campo: any) => campo.valore)
							.map((campo: any) => {
								const campoId = _campi_bando.find((_campo: any) => _campo.nome === campo.nome)?.id;
								return { id: campoId, valore: campo.valore };
							}),
					};

					promises.push(AxiosCaller.post("/api/bando/user/values", _params));
				}
			}

			await Promise.allSettled(promises);
			await orderFields(application_id);

			notify("Bando salvato con successo", "success");
		} catch (e: any) {
			console.error(e);
			setEditErrors(e?.data || {});
			notify(e?.data?.message || "Errore durante il salvataggio del bando", "error");
		} finally {
			setLoading(false);
		}
	};

	const formattedStartDate = application?.start_date ? format(new Date(application?.start_date), "yyyy-MM-dd hh:mm") : "";
	const formattedEndDate = application?.end_date ? format(new Date(application?.end_date), "yyyy-MM-dd hh:mm") : "";

	return (
		<>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h4 className="mb-0">Modifica bando</h4>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CRow>
						<CCol md={4}>
							<BaseInput
								disabled={loading}
								error={editErrors?.nome}
								initialValue={application?.nome}
								inputGroupClassName="mb-3"
								setValue={onNameUpdate}
								stringIcon="Nome"
							/>
						</CCol>
						<CCol md={4}>
							<BaseInput
								disabled={loading}
								error={editErrors?.start_date}
								initialValue={formattedStartDate}
								inputGroupClassName="mb-3"
								setValue={onStartDate}
								stringIcon="Data attivazione"
								type="datetime-local"
							/>
						</CCol>
						<CCol md={4}>
							<BaseInput
								disabled={loading}
								error={editErrors?.end_date}
								initialValue={formattedEndDate}
								inputGroupClassName="mb-3"
								setValue={onEndDate}
								stringIcon="Data chiusura"
								type="datetime-local"
							/>
						</CCol>
					</CRow>
					<CRow>
						<div className="mb-2" style={{ display: "flex", alignItems: "center" }}>
							<h5 className="mb-0" style={{ marginRight: "5px" }}>
								Campi bando
							</h5>
							<CIcon
								icon={cilPlus}
								style={{
									border: "1px solid black",
									borderRadius: "50%",
									padding: "5px",
									width: "25px",
									height: "25px",
									cursor: "pointer",
								}}
								onClick={addCampoBando}
							/>
						</div>

						{!!application?.campi_bando?.length && (
							<CCol md={4}>
								<DragAndDrop
									initialItems={application?.campi_bando?.map((campo: any, index: number) => ({
										name: campo.nome,
										id: `id-${index}`,
										campo_id: campo?.id,
										hasBtnValue: campo?.hasBtnValue,
										hasTooltip: campo?.hasTooltip,
										max_length: campo?.max_length,
										nome: campo?.nome,
										universalVal: campo?.universalVal,
										section: campo?.section,
										className: campo?.section ? "section" : "",
									}))}
									onDnd={onDnd}
									onEdit={onEdit}
								/>
							</CCol>
						)}
					</CRow>
					<CRow>
						<h5>Utenti iscritti</h5>
						<CCol md={12}>
							<BaseInput
								disabled={loading}
								error={editErrors?.users}
								inputGroupClassName="mb-3"
								multiSelectOptions={users?.map(({ alias, p_iva, id }) => {
									const index = application?.iscritti?.findIndex((iscritto: any) => iscritto?.user_id === id) as any;
									const entry = {
										value: id,
										text: `${alias} - ${p_iva}`,
										selected: false,
									};
									if (index > -1) entry.selected = true;
									return entry;
								})}
								setValues={onUsersChange}
								stringIcon="Utenti"
								isMultiselect
							/>
						</CCol>
					</CRow>
					{application?.iscritti && !!application?.iscritti?.length && (
						<CRow className="mt-2">
							<h5>Valori Campi utente</h5>
							{getCampiBandoUtente()}
						</CRow>
					)}
					<CRow className="mt-2">
						<CCol md={12}>
							<h5>Ordina campi utente</h5>
						</CCol>
						{!!userFields?.length && (
							<CCol md={4}>
								<DragAndDrop
									initialItems={userFields?.map((campo: any) => ({
										name: campo.nome,
										id: `${campo.id}`,
										value: campo?.universalVal,
										className: campo?.section ? "section" : "",
									}))}
									onDnd={(arr: any) => setUserFields(arr)}
								/>
							</CCol>
						)}
					</CRow>
					<CButton color="primary" disabled={loading || !application?.nome} onClick={onSave}>
						Salva
					</CButton>
				</CCardBody>
			</CCard>
			<AddField
				fieldToEdit={campoToEdit}
				indexCampoToEdit={indexCampoToEdit}
				setVisible={closeModal}
				visible={visible}
				isBando
				onAdd={onAddCampoBando}
				onEdit={onEditCampoBando}
			/>
		</>
	);
};

export default memo(Applications);
