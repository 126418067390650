/* eslint-disable react/jsx-props-no-spreading */
import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { cilHamburgerMenu, cilPencil } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

type ListItemProps = {
	item: any;
	index: number;
	editMode: boolean;
	onEdit: any;
	className?: string;
};

const ListItem = ({
	item, index, onEdit, className = "",
}: ListItemProps) => (
	<Draggable draggableId={item.id} index={index}>
		{(provided: any) => (
			<div className={className} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				<div
					className="mb-3"
					style={{
						alignItems: "center",
						border: "1px solid black",
						borderRadius: "10px",
						padding: "10px",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<CIcon icon={cilHamburgerMenu} />
					<span>{item.name?.charAt(0)?.toUpperCase() + item.name?.slice(1)}</span>
					<CIcon
						icon={cilPencil}
						style={{
							visibility: onEdit ? "visible" : "hidden",
						}}
						onClick={() => onEdit(index)}
					/>
				</div>
			</div>
		)}
	</Draggable>
);

export default memo(ListItem);
