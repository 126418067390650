import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import {
	CButton,
	CCard,
	CCardBody,
	CCardGroup,
	CCol,
	CContainer,
	CRow,
} from "@coreui/react-pro";
import { cilLockLocked, cilUser } from "@coreui/icons";

import { useLogin } from "../../hooks/auths";
import BaseInput from "../../components/Input/BaseInput/BaseInput";

const Login = () => {
	const { t } = useTranslation();
	const { login, loading, errors } = useLogin();
	const [piva, setPiva] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const navigate = useNavigate();

	const loginOnclick = async () => {
		const loggedIn = await login(piva, password);
		if (loggedIn) navigate("/");
	};

	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={8}>
						<CCardGroup>
							<CCard className="p-4">
								<CCardBody>
									<h1>{t("login")}</h1>
									<p className="text-medium-emphasis">{t("signInToYourAccount")}</p>
									<BaseInput
										error={errors?.p_iva}
										icon={cilUser}
										inputGroupClassName="mb-3"
										placeholder="Partita Iva"
										setValue={(val) => setPiva(val as string)}
										type="text"
									/>
									<BaseInput
										error={errors?.password}
										icon={cilLockLocked}
										inputGroupClassName="mb-4"
										placeholder={t("password")}
										setValue={(val) => setPassword(val as string)}
										type="password"
									/>
									<CRow className="align-items-center">
										<CCol md={4}>
											<CButton className="px-4" color="primary" disabled={loading} onClick={loginOnclick}>
												{t("login")}
											</CButton>
										</CCol>
									</CRow>
								</CCardBody>
							</CCard>
						</CCardGroup>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
};

export default Login;
