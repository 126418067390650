/* eslint-disable camelcase */
import { useCallback, useState } from "react";

import AxiosCaller from "../utils/AxiosCaller";

const useUserFields = ({
	editMode = false,
	userId = null,
	bando_id = null,
}) => {
	const [loading, setLoading] = useState(false);
	const [userFields, setUserFields] = useState([] as any);

	const fetchUsers = useCallback(async (all = false) => {
		try {
			setLoading(true);

			const { data: fields } = (await AxiosCaller.get("/api/campi_utente", {
				params: {
					bando_id,
					all,
				},
			})) as any;

			setUserFields(fields);
			if (editMode) {
				const _userFields = [...fields];
				const { data: campiUtente } = (await AxiosCaller.get(`/api/campi_utente/${userId}`)) as any;
				// eslint-disable-next-line no-restricted-syntax
				for (const campo of campiUtente) {
					const index = _userFields.findIndex((_field: any) => _field.id === campo.id);
					if (index > -1) {
						_userFields[index].valore = campo?.pivot?.valore;
						setUserFields(_userFields);
					}
				}
			}
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [bando_id, editMode, userId]);

	return {
		loading,
		userFields,
		setUserFields,
		fetchUsers,
	};
};

export default useUserFields;
