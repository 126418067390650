import Users from "./pages/Admin/Users/Users";
import Applications from "./pages/Applications/Applications";
import EditApplication from "./pages/EditApplication/EditApplication";
import ViewApplication from "./pages/Users/ViewApplication/ViewApplication";
import Fields from "./pages/Fields/Fields";
import Settings from "./pages/Settings/Settings";

export interface IRoute {
	path: string;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	element?: any;
	exact?: boolean;
	isAdmin?: boolean;
}

const routes = [
	{
		path: "/",
		name: "Utenti",
		element: Users,
		isAdmin: true,
	},
	{
		path: "/applications",
		name: "Bandi",
		element: Applications,
		isAdmin: true,
	},
	{
		path: "/",
		name: "Bandi",
		element: Applications,
		isAdmin: false,
	},
	{
		path: "/fields",
		name: "Campi Utente",
		element: Fields,
		isAdmin: true,
	},
	{
		path: "/applications/:applicationId",
		name: "Modifica",
		element: EditApplication,
		isAdmin: true,
		exact: true,
	},
	{
		path: "/applications/new",
		name: "Modifica",
		element: EditApplication,
		isAdmin: true,
		exact: true,
	},
	{
		path: "/:applicationId",
		name: "Visualizza",
		element: ViewApplication,
		exact: true,
		isAdmin: false,
	},
	{
		path: "/fields",
		name: "Fields",
		element: Users,
		isAdmin: true,
	},
	{
		path: "/settings",
		name: "Fields",
		element: Settings,
		isAdmin: true,
	},
] as IRoute[];

export default routes;
