import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";

import SimpleBar from "simplebar-react";
import { SET_SIDEBAR_SHOW, SET_SIDEBAR_UNFOLDABLE } from "../store/constants";
import { useAdmin, useUserNavbar } from "../store/selectors";
import logo from "../assets/brand/logo-larioht.png";
import { sygnet } from "../assets/brand/sygnet";

import { AppSidebarNav } from "./AppSidebarNav";

// sidebar nav config
import navigation from "../_nav";

const AppSidebar = () => {
	const isAdmin = useAdmin();
	const dispatch = useDispatch();
	const unfoldable = useSelector(({ base }: { base: IBase }) => base.sidebarUnfoldable);
	const sidebarShow = useSelector(({ base }: { base: IBase }) => base.sidebarShow);
	const userNavigation = useUserNavbar();

	useEffect(() => {
		if (userNavigation?.length > 1) {
			setTimeout(() => document.querySelectorAll("a[href^='#section-']").forEach(
				(element) => {
					const _element = document.querySelector(`#${(element as any)?.href?.split("#")?.[1] ?? ""}`) as HTMLElement;
					if (!_element) return;
					_element.style.scrollMarginTop = `calc(${document?.querySelector(".header")?.scrollHeight ?? 0}px + 20px)`;
				},
			), 100);
		} else {
			setTimeout(() => document.querySelectorAll("a[href^='#section-']").forEach(
				(element) => {
					const _element = document.querySelector(`#${(element as any)?.href?.split("#")?.[1] ?? ""}`) as HTMLElement;
					if (!_element) return;
					_element.style.scrollMarginTop = "";
				},
			), 100);
		}
	}, [userNavigation?.length]);

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: SET_SIDEBAR_SHOW, payload: visible });
			}}
		>
			<CSidebarBrand className="d-none d-md-flex">
				<img alt="larioht.it" className="img-fluid p-3 pt-0 pb-0" height="50" src={logo} />
				<CIcon className="sidebar-brand-narrow" height={35} icon={sygnet} />
			</CSidebarBrand>
			<CSidebarNav>
				<SimpleBar>
					<AppSidebarNav items={isAdmin ? navigation : userNavigation} />
				</SimpleBar>
			</CSidebarNav>
			<CSidebarToggler
				className="d-none d-md-flex"
				onClick={() => dispatch({ type: SET_SIDEBAR_UNFOLDABLE, payload: !unfoldable })}
			/>
		</CSidebar>
	);
};

export default React.memo(AppSidebar);
