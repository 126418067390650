import {
	useEffect, useMemo, useState,
} from "react";
import NotificationsSystem, {
	setUpNotifications, useNotifications, wyboTheme,
} from "reapop";
import { useDispatch } from "react-redux";
import { useFetchUser } from "./hooks/auths";
import { useWindowSizes } from "./hooks/useWindowSizes";

import {
	SET_IS_MOBILE, SET_IS_TABLET,
} from "./store/constants";

import MainLayout from "./MainLayout";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ToastContext from "./layout/Context/ToastContext/ToastContext";

export type ToastNotificationType = { message: string; isError: boolean };

const App = () => {
	const { width } = useWindowSizes();
	const { fetchUser } = useFetchUser();
	const { notifications, dismissNotification } = useNotifications();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const [toastNotification, setToastNotification] = useState<ToastNotificationType>({
		message: "",
		isError: false,
	});
	const setToastAndDelay = (value: ToastNotificationType) => {
		setToastNotification(value);
		setTimeout(() => {
			setToastNotification({ message: "", isError: false });
		}, 3000);
	};

	const toastContext = useMemo(
		() => ({ ...toastNotification, setToastNotification: setToastAndDelay }),
		[toastNotification],
	);

	useEffect(() => {
		dispatch({
			type: SET_IS_MOBILE,
			payload: width <= 767,
		});

		dispatch({
			type: SET_IS_TABLET,
			payload: width < 1024 && width > 767,
		});
	}, [dispatch, width]);

	useEffect(() => {
		setUpNotifications({
			defaultProps: {
				position: "top-right",
				dismissible: true,
				showDismissButton: true,
				dismissAfter: 2000,
			},
		});
	}, []);

	useEffect(() => {
		const _fetchUser = async () => {
			try {
				setLoading(true);
				await fetchUser();
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		_fetchUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{toastNotification?.message && (
				<div
					className="position-fixed top-0 end-0 p-3 d-flex align-items-center"
					style={{
						zIndex: 11111111,
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 21, 0.175)",
						borderRadius: "5px",
					}}
				>
					<svg
						className="rounded me-2"
						focusable="false"
						height="20"
						preserveAspectRatio="xMidYMid slice"
						role="img"
						width="20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect fill={toastNotification.isError ? "red" : "green"} height="100%" width="100%" />
					</svg>
					{toastNotification.message}
				</div>
			)}
			<ToastContext.Provider value={toastContext}>
				<ErrorBoundary>
					<NotificationsSystem
						dismissNotification={(id) => dismissNotification(id)}
						notifications={notifications}
						theme={wyboTheme}
					/>
					{!loading && <MainLayout />}
				</ErrorBoundary>
			</ToastContext.Provider>
		</>
	);
};

export default App;
