/* eslint-disable max-lines */
import {
	useCallback, useEffect, useMemo, useState,
} from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
	CButton, CCard, CCardBody, CCardHeader, CCol, CNavItem, CRow,
} from "@coreui/react-pro";
import { useDispatch } from "react-redux";
import { useNotifications } from "reapop";

import { SET_USER_NAVBAR, SET_VIEW_SEARCH } from "../../../store/constants";
import copyToClipboard from "../../../utils/copyToClipboard";
import AxiosCaller from "../../../utils/AxiosCaller";

import classes from "./ViewApplication.module.scss";

const ViewApplication = () => {
	const { notify } = useNotifications();
	const { applicationId } = useParams();
	const [application, setApplication] = useState(null as any);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: SET_VIEW_SEARCH });
		return () => {
			dispatch({ type: SET_VIEW_SEARCH });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchApplication = async () => {
			try {
				const { data: _application } = (await AxiosCaller.get(`/api/bando/${applicationId}`, {
					params: {},
				})) as any;

				setApplication(_application);

				const userNav = [];

				const campiBando = _application.campi_bando_valorizzati_utente
					.filter((campo: any) => campo.section)
					.map((campo: any) => ({
						component: CNavItem,
						name: campo.nome,
						href: `#section-${campo.id}`,
					}));

				if (campiBando.length) {
					userNav.push({
						component: CNavItem,
						name: "Campi specifici del bando",
						href: "#campi-bando",
					});

					userNav.push(...campiBando);
				}

				const campiUtenteValorizzati = _application.campi_utente_valorizzati
					.filter((campo: any) => campo.section)
					.map((campo: any) => ({
						component: CNavItem,
						name: campo.nome,
						href: `#section-${campo.id}`,
					}));

				if (campiUtenteValorizzati.length) {
					userNav.push({
						component: CNavItem,
						name: "Campi specifici utente",
						href: "#campi-utente",
					});

					userNav.push(
						...campiUtenteValorizzati,
					);
				}

				dispatch({ type: SET_USER_NAVBAR, payload: userNav });
			} catch (e) {
				console.error(e);
			}
		};

		if (applicationId) fetchApplication();
	}, [applicationId, dispatch]);

	useEffect(
		() => () => {
			dispatch({ type: SET_USER_NAVBAR, payload: [] });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const generateFields = useCallback(
		(fields: any) => fields.map((campo: any) => {
			const valore = campo.is_universal ? campo.universalVal : campo.valore;

			const {
				nome, id, hasTooltip, hasBtnValue, is_highlighted: isHighlighted, section,
			} = campo;

			if (section) {
				return (
					<div className={classes.sectionRow} key={id}>
						<div className={classes.section} id={`section-${id}`}>
							{nome}
						</div>
					</div>
				);
			}

			if (!valore) return null;

			return (
				<div className={classes.fieldRow} key={id}>
					<div className={classes.fieldLabel}>{nome}</div>
					<div className={classes.fieldButton}>
						<CButton
							color={isHighlighted ? "danger" : "primary"}
							data-tooltip-content={valore}
							data-tooltip-id={id}
							variant="outline"
							onClick={() => {
								copyToClipboard(valore);
								notify(`Campo "${nome}" copiato`, "success");
							}}
						>
							{hasBtnValue ? valore : `Copia campo ${nome}`}
						</CButton>
						{hasTooltip && <Tooltip id={id} />}
					</div>
				</div>
			);
		}),
		[notify],
	);

	const userFields = useMemo(() => {
		const campi = application?.campi_utente_valorizzati || [];
		return generateFields(campi);
	}, [application?.campi_utente_valorizzati, generateFields]);

	const applicationFields = useMemo(() => {
		const campi = application?.campi_bando_valorizzati_utente || [];
		return generateFields(campi);
	}, [application?.campi_bando_valorizzati_utente, generateFields]);

	return (
		<CCard>
			<CCardHeader>
				<CRow className="justify-content-between">
					<CCol className="d-flex justify-content-between align-items-center" md={12}>
						<h4 className="mb-0">{application?.nome || ""}</h4>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
				{!!applicationFields.length
					&& (
						<>
							<div className={classes.sectionRow}>
								<h4 className={classes.section} id="campi-bando">
									Campi specifici del bando
								</h4>
							</div>
							<div className={classes.fieldsContainer}>{applicationFields}</div>
						</>
					)}
				{!!userFields.length && (
					<>
						<div className={classes.sectionRow}>
							<h4 className={classes.section} id="campi-utente">
								Campi specifici utente
							</h4>
						</div>
						<div className={classes.fieldsContainer}>{userFields}</div>
					</>
				)}
			</CCardBody>
		</CCard>
	);
};

export default ViewApplication;
