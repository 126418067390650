import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../routes";
import { useAdmin } from "../../store/selectors";

const AdminRoutes = () => {
	const isAdmin = useAdmin();
	if (!isAdmin) return <Navigate to="/404" />;
	return (
		<Routes>
			{routes
				.filter((route) => route.isAdmin)
				.map(
					(route) => route.element && (
						<Route element={<route.element />} key={route.path} path={route.path} />
					),
				)}
		</Routes>
	);
};

export default memo(AdminRoutes);
