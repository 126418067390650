/* eslint-disable max-lines */
import {
	memo, useContext, useEffect, useState,
} from "react";
import {
	CButton, CFormCheck, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
} from "@coreui/react-pro";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";
import AxiosCaller from "../../../utils/AxiosCaller";
import ToastContext from "../../../layout/Context/ToastContext/ToastContext";

const AddField = ({
	visible, setVisible, fieldToEdit, isBando = false, onEdit, onAdd, indexCampoToEdit = -1,
}: any) => {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(fieldToEdit?.nome || "");
	const [hasTooltip, setHasTooltip] = useState(fieldToEdit?.hasTooltip || false);
	const [maxLength, setMaxLength] = useState(fieldToEdit?.max_length || 0);
	const [hasBtnValue, setHasBtnValue] = useState(fieldToEdit?.hasBtnValue || false);
	const [universalValue, setUniversalValue] = useState(fieldToEdit?.universalVal || "");
	const [isHighlighted, setIsHighlighted] = useState(fieldToEdit?.is_highlighted || false);
	const [section, setSection] = useState(fieldToEdit?.section || false);
	const [enabled, setEnabled] = useState(fieldToEdit?.enabled || true);
	const { setToastNotification } = useContext(ToastContext);

	const [errors, setErrors] = useState({} as any);

	useEffect(() => {
		setName(fieldToEdit?.nome);
		setHasTooltip(fieldToEdit?.hasTooltip);
		setMaxLength(fieldToEdit?.max_length);
		setHasBtnValue(fieldToEdit?.hasBtnValue);
		setUniversalValue(fieldToEdit?.universalVal);
		setIsHighlighted(fieldToEdit?.is_highlighted);
		setSection(fieldToEdit?.section);
		setEnabled(fieldToEdit?.enabled);
	}, [
		fieldToEdit?.hasBtnValue,
		fieldToEdit?.hasTooltip,
		fieldToEdit?.max_length,
		fieldToEdit?.nome,
		fieldToEdit?.universalVal,
		fieldToEdit?.is_highlighted,
		fieldToEdit?.section,
		fieldToEdit?.enabled,
	]);

	const closeModal = () => {
		setVisible();
		setName("");
		setHasTooltip(false);
		setMaxLength(0);
		setHasBtnValue(false);
		setIsHighlighted(false);
		setUniversalValue("");
		setSection(false);
		setEnabled(true);
		setErrors({} as any);
	};

	const addEditField = async () => {
		try {
			setLoading(true);
			const isEdit = !!fieldToEdit?.nome;
			if (isBando) {
				if (isEdit) {
					onEdit(
						{
							nome: name,
							hasTooltip,
							max_length: maxLength,
							hasBtnValue,
							universalVal: universalValue,
							is_highlighted: isHighlighted,
							section,
							enabled,
							id: fieldToEdit?.id,
						},
						indexCampoToEdit,
					);
				} else {
					onAdd({
						nome: name,
						hasTooltip,
						max_length: maxLength,
						hasBtnValue,
						universalVal: universalValue,
						is_highlighted: isHighlighted,
						section,
						enabled,
					});
				}
			} else {
				if (!isEdit) {
					const params = {
						nome: name,
						hasTooltip,
						max_length: maxLength,
						hasBtnValue,
						universalVal: universalValue,
						is_highlighted: isHighlighted,
						section,
						enabled,
					};

					(await AxiosCaller.post("/api/campi_utente", params)) as any;
				} else {
					const params = {
						nome: name,
						hasTooltip,
						max_length: maxLength,
						hasBtnValue,
						universalVal: universalValue,
						id: fieldToEdit?.id,
						is_highlighted: isHighlighted,
						section,
						enabled,
					};

					(await AxiosCaller.post(`/api/campi_utente/${fieldToEdit?.id}`, params)) as any;
				}

				setToastNotification({
					isError: false,
					message: "Azione eseguita con successo",
				});
				setVisible(false);
			}
		} catch (e) {
			console.error(e);
			setToastNotification({
				isError: true,
				message: "Errore durante l'azione",
			});
			setVisible(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<CModal alignment="center" visible={visible} scrollable onClose={closeModal}>
			<CModalHeader>
				<CModalTitle>Modifica campo</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<BaseInput
					disabled={loading}
					error={errors?.nome}
					initialValue={name}
					inputGroupClassName="mb-3"
					setValue={(val) => setName(val)}
					stringIcon="Nome"
					type="text"
				/>
				<BaseInput
					disabled={loading}
					error={errors?.max_length}
					initialValue={maxLength}
					inputGroupClassName="mb-3"
					setValue={(val) => setMaxLength(val)}
					stringIcon="Lunghezza Massima "
					type="number"
				/>
				<BaseInput
					disabled={!fieldToEdit.is_universal && fieldToEdit.id}
					error={errors?.universalVal}
					initialValue={universalValue}
					inputGroupClassName="mb-3"
					setValue={(val) => setUniversalValue(val)}
					stringIcon="Valore universale"
					type="text"
				/>
				<CFormCheck
					checked={hasTooltip}
					disabled={loading}
					id="hastooltip"
					label="Tooltip"
					type="checkbox"
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setHasTooltip((prevActive: any) => !prevActive);
					}}
				/>
				<CFormCheck
					checked={hasBtnValue}
					disabled={loading}
					id="hasbtnvalue"
					label="Button Value"
					type="checkbox"
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setHasBtnValue((prevActive: any) => !prevActive);
					}}
				/>
				<CFormCheck
					checked={isHighlighted}
					disabled={loading}
					id="isHighlighted"
					label="Campo evidenziato"
					type="checkbox"
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setIsHighlighted((prevActive: any) => !prevActive);
					}}
				/>
				<CFormCheck
					checked={section}
					disabled={loading}
					id="section"
					label="Sezione"
					type="checkbox"
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setSection((prevActive: any) => !prevActive);
					}}
				/>
				<CFormCheck
					checked={enabled}
					disabled={loading}
					id="abilitato"
					label="Abilitato"
					type="checkbox"
					onChange={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setEnabled((_enabled: any) => !_enabled);
					}}
				/>
			</CModalBody>
			<CModalFooter>
				<CButton color="secondary" disabled={loading} onClick={closeModal}>
					Close
				</CButton>
				<CButton color="primary" disabled={loading || !name} onClick={addEditField}>
					Save changes
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default memo(AddField);
