import {
	SET_USER,
	SET_IS_MOBILE,
	SET_IS_TABLET,
	SET_SIDEBAR_SHOW,
	SET_THEME,
	SET_SIDEBAR_UNFOLDABLE,
	SET_USER_NAVBAR,
	SET_VIEW_SEARCH,
} from "./constants";

const initialState = {
	user: {},
	authenticated: false,
	sidebarUnfoldable: false,
	sidebarShow: true,
	theme: "default",
	userNavbar: [],
	viewSearch: false,
} as IBase;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = initialState, store: any) => {
	switch (store.type) {
		case SET_USER: {
			const user = store.payload;

			if (user) {
				return {
					...state,
					user: store.payload,
					authenticated: true,
					isAdmin: user?.is_admin,
				};
			}

			return {
				...state,
				user: null,
				authenticated: false,
				isAdmin: false,
			};
		}
		case SET_IS_MOBILE:
			return { ...state, isMobile: store.payload };
		case SET_IS_TABLET:
			return { ...state, isTablet: store.payload };
		case SET_SIDEBAR_SHOW:
			return { ...state, sidebarShow: store.payload };
		case SET_SIDEBAR_UNFOLDABLE:
			return { ...state, sidebarUnfoldable: store.payload };
		case SET_THEME:
			return { ...state, theme: store.payload };
		case SET_USER_NAVBAR:
			return { ...state, userNavbar: store.payload };
		case SET_VIEW_SEARCH:
			return { ...state, viewSearch: !state.viewSearch };
		default:
			return { ...state };
	}
};

export default reducer;
