import {
	cilSettings,
	cilUser,
	cilUserPlus,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
	{
		component: CNavItem,
		name: "Utenti",
		to: "/",
		icon: cilUser,
	},
	{
		component: CNavItem,
		name: "Bandi",
		to: "/applications",
		icon: cilSettings,
	},
	{
		component: CNavItem,
		name: "Campi Utente",
		to: "/fields",
		icon: cilUserPlus,
	},
	{
		component: CNavTitle,
		name: "Impostazioni",
	},
	{
		component: CNavItem,
		name: "Impostazioni",
		to: "/settings",
		icon: cilSettings,
	},
];

export default _nav;
