const copyToClipboard = (text: string) => {
	if (!navigator.clipboard) {
		const el = document.createElement("textarea");
		el.value = text;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	} else {
		navigator.clipboard.writeText(text);
	}
};

export default copyToClipboard;
