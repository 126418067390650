import React from "react";
import { CFooter } from "@coreui/react-pro";

const AppFooter = () => (
	<CFooter>
		<div>
			<a href="https://larioht.it" rel="noopener noreferrer" target="_blank">
                larioht.it
			</a>
			<span className="ms-1">&copy; 2023 Lario HT srl.</span>
		</div>
	</CFooter>
);

export default React.memo(AppFooter);
