/* eslint-disable max-lines */
/* eslint-disable react/no-unstable-nested-components */
import { cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
	CButton,
	CCard,
	CCardBody,
	CCol,
	CRow,
	CCardHeader,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react-pro";
import {
	memo, useContext, useEffect, useState,
} from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../store/selectors";
import { humanize, humanizeValue } from "../../utils/humanize";
import AxiosCaller from "../../utils/AxiosCaller";
import ToastContext from "../../layout/Context/ToastContext/ToastContext";
import BaseInput from "../../components/Input/BaseInput/BaseInput";

const Applications = () => {
	const [loading, setLoading] = useState(false);
	const [applications, setApplications] = useState([]);
	const [applicationToDelete, setApplicationToDelete] = useState({} as any);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const { setToastNotification } = useContext(ToastContext);
	const navigate = useNavigate();
	const isAdmin = useAdmin();
	const [filterText, setFilterText] = useState("");

	const filteredItems = applications.filter(
		(item: any) => item.nome && item.nome.toLowerCase().includes(filterText.toLowerCase()),
	);

	const fetchUsers = async () => {
		try {
			setLoading(true);
			const { data: _users } = (await AxiosCaller.get("/api/bando", {
				params: {},
			})) as any;
			setApplications(_users);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	const onAddApplication = () => {
		navigate("/applications/new");
	};

	const closeDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setApplicationToDelete({});
	};

	const onDeleteConfirm = (application: any) => {
		setApplicationToDelete(application);
		setIsDeleteModalVisible(true);
	};

	const onDelete = async () => {
		try {
			setLoading(true);
			(await AxiosCaller.delete(`/api/bando/${applicationToDelete?.id}`)) as any;
			setToastNotification({
				isError: false,
				message: "Hai elimminato correttamente il bando",
			});
			await fetchUsers();
			closeDeleteModal();
		} catch (e) {
			console.error(e);
			setToastNotification({
				isError: true,
				message: "Errore durante la rimozione del bando",
			});
		} finally {
			setLoading(false);
		}
	};

	const getColumns = () => {
		if (!applications || !applications.length) return [];
		const columns = Object.keys(applications[0]).map(
			(application) => ({
				id: application,
				name: humanize(application),
				sortable: true,
				format: (row: any) => humanizeValue(application, row[application]),
				selector: (row: any) => row?.[application],
			} as any),
		);

		if (isAdmin) {
			columns.push({
				cell: (application: any) => <CIcon icon={cilTrash} onClick={() => onDeleteConfirm(application)} />,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			});

			columns.push({
				cell: (application: any) => (
					<CIcon
						icon={cilPencil}
						onClick={() => {
							navigate(`/${isAdmin ? "applications/" : ""}${application.id}`);
						}}
					/>
				),
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			});
		}

		return columns;
	};

	return (
		<>
			<CModal alignment="center" backdrop="static" visible={isDeleteModalVisible} onClose={closeDeleteModal}>
				<CModalHeader>
					<CModalTitle>Modal</CModalTitle>
				</CModalHeader>
				<CModalBody>Sei sicuro di voler eliminare l`utente?</CModalBody>
				<CModalFooter>
					<CButton color="secondary" disabled={loading} onClick={closeDeleteModal}>
						Close
					</CButton>
					<CButton color="primary" disabled={loading} onClick={onDelete}>
						Delete
					</CButton>
				</CModalFooter>
			</CModal>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h4 className="mb-0">Bandi</h4>
							{isAdmin && (
								<CButton color="primary" disabled={loading} variant="outline" onClick={onAddApplication}>
									<CIcon className="mw-3" icon={cilPlus} />
								</CButton>
							)}
						</CCol>
					</CRow>
					<BaseInput
						disabled={false}
						error=""
						id="search-ref"
						initialValue=""
						inputGroupClassName="mb-3"
						setValue={(val: any) => setFilterText(val)}
						stringIcon="Ricerca"
					/>
				</CCardHeader>

				<CCardBody>
					<DataTable
						// className={className}
						columns={getColumns()}
						data={filteredItems}
						// paginationPerPage={rowPerPage}
						// paginationRowsPerPageOptions={[10, 20, 50, 100]}
						// paginationTotalRows={application.length}
						progressPending={loading}
						highlightOnHover
						// onChangeRowsPerPage={(perPage: number) => setRowPerPage(perPage)}
						pagination
						// paginationServer
						pointerOnHover
						onRowClicked={(application: any) => navigate(`/${isAdmin ? "applications/" : ""}${application.id}`)}
					/>
				</CCardBody>
			</CCard>
		</>
	);
};

export default memo(Applications);
