/* eslint-disable max-lines */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import {
	memo, useContext, useEffect, useState,
} from "react";
import {
	CButton, CFormCheck, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
} from "@coreui/react-pro";

import useUserFields from "../../../../hooks/useUserFields";

import BaseInput from "../../../../components/Input/BaseInput/BaseInput";

import AxiosCaller from "../../../../utils/AxiosCaller";
import ToastContext from "../../../../layout/Context/ToastContext/ToastContext";

type AddUserProps = {
	setVisible: (isVisible: boolean) => void;
	visible: boolean;
	editMode?: boolean;
	initPiva?: string;
	initAlias?: string;
	initActive?: boolean;
	initSpidUser?: string;
	initSpidPin?: string;
	initSpidType?: string;
	initPwdFirmaDigitale?: string;
	userId?: string;
	refetchUsers: () => void;
};

const AddUser = ({
	visible,
	setVisible,
	editMode = false,
	initPiva = "",
	userId = "",
	initActive = false,
	initAlias = "",
	refetchUsers,
	initSpidUser = "",
	initSpidPin = "",
	initSpidType = "",
	initPwdFirmaDigitale = "",
}: AddUserProps) => {
	const [errors, setErrors] = useState({} as any);
	const [piva, setPiva] = useState(initPiva);
	const [password, setPassword] = useState("");
	const [spidUser, setSpidUser] = useState(initSpidUser);
	const [spidPin, setSpidPin] = useState(initSpidPin);
	const [spidType, setSpidType] = useState(initSpidType);
	const [pwdFirmaDigitale, setPwdFirmaDigitale] = useState(initPwdFirmaDigitale);
	const [active, setActive] = useState(initActive);
	const [alias, setAlias] = useState(initAlias);
	const {
		fetchUsers, userFields, loading: loadingUserFields, setUserFields,
	} = useUserFields({ userId, editMode } as any);
	const [loading, setLoading] = useState(false);
	const { setToastNotification } = useContext(ToastContext);

	useEffect(() => {
		setPiva(initPiva);
		setActive(initActive);
		setAlias(initAlias);
		setSpidUser(initSpidUser);
		setSpidPin(initSpidPin);
		setSpidType(initSpidType);
		setPwdFirmaDigitale(initPwdFirmaDigitale);
	}, [initPiva, initActive, initAlias, initSpidUser, initSpidPin, initSpidType, initPwdFirmaDigitale]);

	useEffect(() => {
		if (visible) fetchUsers();
	}, [editMode, visible, userId, fetchUsers]);

	const onAddUser = async () => {
		try {
			setLoading(true);
			(await AxiosCaller.post(`/api/user${editMode ? `/${userId}` : ""}`, {
				alias,
				p_iva: piva,
				password,
				spid_pin: spidPin,
				spid_user: spidUser,
				spid_type: spidType,
				pwd_firma_digitale: pwdFirmaDigitale,
				active,
				campi_utente: userFields.map((_field: any) => ({ valore: _field.valore, id: _field.id })),
			})) as any;
			setToastNotification({
				isError: false,
				message: "Hai salvato correttamente l'utente",
			});
			await refetchUsers();
			setVisible(false);
		} catch (e: any) {
			setErrors(e.data.errors);
		} finally {
			setLoading(false);
		}
	};

	const resetAndClose = () => {
		setVisible(false);
		setPiva("");
		setPassword("");
		setSpidUser("");
		setSpidPin("");
		setSpidType("");
		setPwdFirmaDigitale("");
		setActive(false);
		setAlias("");
		setUserFields([]);
		setErrors({});
	};

	return (
		<CModal alignment="center" size="xl" visible={visible} scrollable onClose={resetAndClose}>
			<CModalHeader>
				<CModalTitle>Creazione/Modifica Utente</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<BaseInput
					disabled={loading}
					error={errors?.p_iva}
					initialValue={piva}
					inputGroupClassName="mb-3"
					placeholder="Partita Iva"
					setValue={(val: any) => setPiva(val)}
					stringIcon="P.IVA"
					type="text"
				/>
				<BaseInput
					disabled={loading}
					error={errors?.password}
					inputGroupClassName="mb-3"
					placeholder="Password"
					setValue={(val: any) => setPassword(val)}
					stringIcon="Password"
					type="password"
				/>
				<BaseInput
					disabled={loading}
					error={errors?.alias}
					initialValue={alias}
					inputGroupClassName="mb-3"
					placeholder="Alias"
					setValue={(val: any) => setAlias(val)}
					stringIcon="Alias"
					type="text"
				/>
				<BaseInput
					disabled={loading}
					error={errors?.spid_user}
					initialValue={spidUser}
					inputGroupClassName="mb-3"
					placeholder="SPID Utente"
					setValue={(val: any) => setSpidUser(val)}
					stringIcon="SPID Utente"
					type="text"
				/>
				<BaseInput
					disabled={loading}
					error={errors?.spid_pin}
					initialValue={spidPin}
					inputGroupClassName="mb-3"
					placeholder="SPID Pin"
					setValue={(val: any) => setSpidPin(val)}
					stringIcon="SPID Pin"
					type="text"
				/>
				<CFormSelect
					className="mb-3"
					disabled={loading}
					label="Tipo SPID"
					value={spidType ?? ""}
					onChange={(evt) => setSpidType(evt.target.value)}
				>
					<option value="" disabled>Seleziona tipo SPID</option>
					<option value="Aruba">Aruba</option>
					<option value="Poste ID">Poste ID</option>
					<option value="Namirial">Namirial</option>
					<option value="Sielte">Sielte</option>
					<option value="InfoCert">InfoCert</option>
					<option value="Infocamere">Infocamere</option>
					<option value="EtnaID">EtnaID</option>
					<option value="Lepida">Lepida</option>
					<option value="TIMid">TIMid</option>
					<option value="SpidItalia">SpidItalia</option>
					<option value="Team System ID">Team System ID</option>
				</CFormSelect>
				<BaseInput
					disabled={loading}
					error={errors?.pwd_firma_digitale}
					initialValue={pwdFirmaDigitale}
					inputGroupClassName="mb-3"
					placeholder="Password Firma Digitale"
					setValue={(val: any) => setPwdFirmaDigitale(val)}
					stringIcon="PWD Firma"
					type="text"
				/>
				<CFormCheck
					checked={active}
					disabled={loading}
					id="gridCheck"
					label="Attivo"
					type="checkbox"
					onChange={(e) => {
						e.stopPropagation();
						setActive((prevActive) => !prevActive);
					}}
				/>
				<h5 className="mt-3 mb-3">Campi utente</h5>
				{userFields.map(({
					nome, max_length, valore, id,
				}: any) => (
					<BaseInput
						disabled={loading}
						error={errors?.nome || (valore?.length > max_length && !!max_length)}
						initialValue={valore}
						inputGroupClassName="mb-3"
						key={id}
						setValue={(val) => {
							const _userFields = [...userFields];
							const index = _userFields.findIndex((_field: any) => _field.id === id);
							if (index > -1) {
								_userFields[index].valore = val;
								setUserFields(_userFields);
							}
						}}
						stringIcon={nome?.charAt(0)?.toUpperCase() + nome?.slice(1)}
						type="text"
					/>
				))}
			</CModalBody>
			<CModalFooter>
				<CButton color="secondary" disabled={loading || loadingUserFields} onClick={resetAndClose}>
					Close
				</CButton>
				<CButton color="primary" disabled={loading || loadingUserFields || !piva} onClick={onAddUser}>
					Save changes
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default memo(AddUser);
