import { memo, useState } from "react";
import {
	CButton, CCard, CCardBody, CCardHeader, CCol, CRow,
} from "@coreui/react-pro";
import { useNotifications } from "reapop";
import AxiosCaller from "../../utils/AxiosCaller";
import Modal from "../../layout/Modal/Modal";
import BaseInput from "../../components/Input/BaseInput/BaseInput";

const Settings = () => {
	const { notify } = useNotifications();
	const [password, setPassword] = useState("");
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({} as any);

	const reset = async () => {
		try {
			setLoading(true);
			(await AxiosCaller.post("/api/user/admin/resetAllPasswords", {
				password,
			})) as any;
			notify("Hai resettato correttamente le password", "success");
		} catch (e: any) {
			notify("Errore durante il reset delle password", "error");
			setErrors(e.data.errors);
		} finally {
			setLoading(false);
		}
	};

	const enable = async () => {
		try {
			(await AxiosCaller.post("/api/user/admin/enableAllUsers", {
				params: {},
			})) as any;
			notify("Hai abilitato gli utenti", "success");
		} catch (e) {
			notify("Errore durante l'abilitazione degli utenti", "error");
		} finally {
			setLoading(false);
		}
	};

	const disable = async () => {
		try {
			(await AxiosCaller.post("/api/user/admin/disableAllUsers", {
				params: {},
			})) as any;
			notify("Hai disabilitato gli utenti", "success");
		} catch (e) {
			notify("Errore durante la disabilitazione degli utenti", "error");
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h4 className="mb-0">Impostazioni</h4>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CCard className="mb-3">
						<CCardBody>
							<h5>Reset tutte le password</h5>
							<CButton color="primary" disabled={loading} onClick={() => setVisible(true)}>
								Reset
							</CButton>
						</CCardBody>
					</CCard>
					<CCard className="mb-3">
						<CCardBody>
							<h5>Attiva tutti gli utenti</h5>
							<CButton color="primary" disabled={loading} onClick={enable}>
								Abilita
							</CButton>
						</CCardBody>
					</CCard>
					<CCard className="mb-3">
						<CCardBody>
							<h5>Disattiva tutti gli utenti</h5>
							<CButton color="primary" disabled={loading} onClick={disable}>
								Disabilita
							</CButton>
						</CCardBody>
					</CCard>
				</CCardBody>
			</CCard>
			<Modal
				disabled={!password || loading}
				setVisible={() => setVisible(false)}
				title="Reset password"
				visible={visible}
				onSuccess={reset}
			>
				<div>
					<BaseInput
						disabled={loading}
						error={errors?.password}
						inputGroupClassName="mb-3"
						placeholder="Password"
						setValue={(val: any) => setPassword(val)}
						stringIcon="Password"
						type="password"
					/>
				</div>
			</Modal>
		</>
	);
};

export default memo(Settings);
