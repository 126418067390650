import React, { Suspense } from "react";
import { CContainer, CSpinner } from "@coreui/react-pro";
import { useAdmin } from "../store/selectors";
import AdminRoutes from "./AdminRoutes/AdminRoutes";
import UserRoutes from "./UserRoutes/UserRoutes";

const AppContent = () => {
	const isAdmin = useAdmin();

	return (
		<CContainer lg>
			<Suspense fallback={<CSpinner color="primary" />}>
				{isAdmin ? <AdminRoutes /> : <UserRoutes />}
			</Suspense>
		</CContainer>
	);
};

export default React.memo(AppContent);
