import AppContent from "../../layout/AppContent";
import AppFooter from "../../layout/AppFooter";
import AppHeader from "../../layout/AppHeader";
import AppSidebar from "../../layout/AppSidebar";

const Home = () => (
	<>
		<AppSidebar />
		<div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
			<AppHeader />
			<div className="body flex-grow-1 px-3">
				<AppContent />
			</div>
			<AppFooter />
		</div>
	</>
);

export default Home;
