/* eslint-disable max-lines */
/* eslint-disable react/no-unstable-nested-components */
import {
	memo, useContext, useEffect, useState,
} from "react";
import { cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import {
	CCardBody,
	CRow,
	CCardHeader,
	CCard,
	CButton,
	CCol,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import DataTable from "react-data-table-component";
import AxiosCaller from "../../utils/AxiosCaller";
import { humanize, humanizeValue } from "../../utils/humanize";
import ToastContext from "../../layout/Context/ToastContext/ToastContext";
import AddField from "./AddField/AddField";
import BaseInput from "../../components/Input/BaseInput/BaseInput";

const Fields = () => {
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [fieldToEdit, setFieldToEdit] = useState({} as any);
	const [fields, setFields] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const { setToastNotification } = useContext(ToastContext);

	const [filterText, setFilterText] = useState("");

	const filteredItems = fields.filter(
		(item: any) => item.nome && item.nome.toLowerCase().includes(filterText.toLowerCase()),
	);
	const fetchFields = async () => {
		try {
			setLoading(true);
			const { data: _fields } = (await AxiosCaller.get("/api/campi_utente", {
				params: {
					all: true,
				},
			})) as any;
			setFields(_fields);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchFields();
	}, []);

	const closeDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setFieldToEdit({});
	};

	const onDeleteConfirm = (field: any) => {
		setFieldToEdit(field);
		setIsDeleteModalVisible(true);
	};

	const onDelete = async () => {
		try {
			setLoading(true);
			(await AxiosCaller.delete(`/api/campi_utente/${fieldToEdit?.id}`)) as any;
			setToastNotification({
				isError: false,
				message: "Hai eliminato correttamente il campo",
			});
			await fetchFields();
			closeDeleteModal();
		} catch (e) {
			console.error(e);
			setToastNotification({
				isError: true,
				message: "Errore durante la rimozione del campo",
			});
		} finally {
			setLoading(false);
		}
	};

	const onClose = async () => {
		setVisible(false);
		setEditMode(false);
		setFieldToEdit({});
		await fetchFields();
	};

	const getColumns = () => {
		if (!fields || !fields.length) return [];
		const columns = Object.keys(fields[0])
			.filter((key) => key !== "max_length" && key !== "universalVal")
			.map(
				(field) => ({
					id: field,
					name: humanize(field),
					sortable: true,
					format: (row: any) => humanizeValue(field, row[field]),
					selector: (row: any) => row?.[field],
				} as any),
			);

		columns.push({
			cell: (field: any) => <CIcon icon={cilTrash} onClick={() => onDeleteConfirm(field)} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});

		columns.push({
			cell: (field: any) => (
				<CIcon
					icon={cilPencil}
					onClick={() => {
						setEditMode(true);
						setFieldToEdit(field);
						setVisible(true);
					}}
				/>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});
		return columns;
	};
	return (
		<>
			<CModal alignment="center" backdrop="static" visible={isDeleteModalVisible} onClose={closeDeleteModal}>
				<CModalHeader>
					<CModalTitle>Modal</CModalTitle>
				</CModalHeader>
				<CModalBody>Sei sicuro di voler eliminare il campo?</CModalBody>
				<CModalFooter>
					<CButton color="secondary" disabled={loading} onClick={closeDeleteModal}>
						Close
					</CButton>
					<CButton color="primary" disabled={loading} onClick={onDelete}>
						Delete
					</CButton>
				</CModalFooter>
			</CModal>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h4 className="mb-0">Campi Utente</h4>
							<CButton color="primary" disabled={loading} variant="outline" onClick={() => setVisible(true)}>
								<CIcon className="mw-3" icon={cilPlus} />
							</CButton>
						</CCol>
					</CRow>
					<BaseInput
						disabled={false}
						error=""
						id="search-ref"
						initialValue=""
						inputGroupClassName="mb-3"
						setValue={(val: any) => setFilterText(val)}
						stringIcon="Ricerca"
					/>
				</CCardHeader>
				<CCardBody>
					<DataTable
						// className={className}
						columns={getColumns()}
						data={filteredItems}
						// paginationPerPage={rowPerPage}
						// paginationRowsPerPageOptions={[10, 20, 50, 100]}
						// paginationTotalRows={field.length}
						progressPending={loading}
						pagination
						onRowClicked={(field: any) => {
							setEditMode(true);
							setFieldToEdit(field);
							setVisible(true);
						}}
						// onChangeRowsPerPage={(perPage: number) => setRowPerPage(perPage)}
						highlightOnHover
						// paginationServer
						pointerOnHover
					/>
				</CCardBody>
			</CCard>
			<AddField fieldToEdit={fieldToEdit} setVisible={onClose} visible={visible} />
		</>
	);
};

export default memo(Fields);
