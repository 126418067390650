/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import { memo, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import List from "./List/List";
import { StrictModeDroppable } from "./Droppable/Droppable";

const reorder = (list: any, startIndex: any, endIndex: any) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const DragAndDrop = ({
	initialItems = [], editMode = false, onEdit, onDnd,
}: any) => {
	const [items, setItems] = useState(initialItems);

	useEffect(() => {
		setItems(initialItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialItems.length]);

	function onDragEnd(result: any) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const _items = reorder(items, result.source.index, result.destination.index) as any[];

		setItems(_items);
		onDnd(_items);
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<StrictModeDroppable droppableId="list">
				{(provided: any) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						<List editMode={editMode} items={items} onEdit={onEdit} />
						{provided.placeholder}
					</div>
				)}
			</StrictModeDroppable>
		</DragDropContext>
	);
};

export default memo(DragAndDrop);
