/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import AxiosCaller from "../utils/AxiosCaller";
import { SET_USER } from "../store/constants";

export const useLogin = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const login = useCallback(async (p_iva: string, password: string) => {
		try {
			setLoading(true);
			setErrors(null);

			await AxiosCaller.get("/sanctum/csrf-cookie");
			await AxiosCaller.post("/api/login", {
				p_iva, password,
			});

			const { data: user }: { data: User } = await AxiosCaller.get("/api/user");
			dispatch({ type: SET_USER, payload: user });

			return true;
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}

		return false;
	}, [dispatch]);

	return {
		login,
		loading,
		errors,
	};
};

export const useRegister = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const register = useCallback(async (newUser: any) => {
		try {
			setLoading(true);
			setErrors(null);

			await AxiosCaller.get("/sanctum/csrf-cookie");
			await AxiosCaller.post("/api/register", newUser);

			const { data: user }: { data: User } = await AxiosCaller.get("/api/user");
			dispatch({ type: SET_USER, payload: user });

			return true;
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}

		return false;
	}, [dispatch]);

	return {
		register,
		loading,
		errors,
	};
};

export const useLogout = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const logout = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);

			await AxiosCaller.post("/api/logout");
			dispatch({ type: SET_USER, payload: null });

			return true;
		} catch (e: any) {
			console.error(e?.data?.errors);
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}

		return false;
	}, [dispatch]);

	return {
		logout,
		loading,
		errors,
	};
};

export const useFetchUser = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();

	const fetchUser = useCallback(async () => {
		try {
			setLoading(true);

			const { data: user }: { data: User } = await axios.get("/api/user");
			dispatch({ type: SET_USER, payload: user });
		} catch (e) {
			dispatch({ type: SET_USER, payload: null });
		} finally {
			setLoading(false);
		}
	}, [dispatch]);

	return {
		fetchUser,
		loading,
	};
};