import { useSelector } from "react-redux";

export const useUser = () => useSelector(({ base }: { base: IBase }) => base?.user);
export const useImpersonated = () => useSelector(({ base }: { base: IBase }) => base?.user?.is_impersonated);
export const useAuthenticated = () => useSelector(({ base }: { base: IBase }) => base?.authenticated);
export const useAdmin = () => useSelector(({ base }: { base: IBase }) => base?.isAdmin);
export const useIsMobile = () => useSelector(({ base }: { base: IBase }) => base?.isMobile);
export const useIsTablet = () => useSelector(({ base }: { base: IBase }) => base?.isTablet);
export const useUserNavbar = () => useSelector(({ base }: { base: IBase }) => base?.userNavbar);
export const useViewSearch = () => useSelector(({ base }: { base: IBase }) => base?.viewSearch);
