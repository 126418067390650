import { memo } from "react";
import ListItem from "../ListItem/ListItem";

type ListProps = {
	items: any[];
	editMode: boolean;
	onEdit: (id: any) => any;
};

const List = ({
	items, editMode, onEdit,
}: ListProps) => (
	<>
		{items.map((item: any, index: number) => (
			<ListItem className={item?.className} editMode={editMode} index={index} item={item} key={item.id} onEdit={onEdit} />
		))}
	</>
);

export default memo(List);
