/* eslint-disable max-lines */
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CNavLink,
	CNavItem,
	CButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
	cilMenu,
} from "@coreui/icons";
import { useState } from "react";
import { useNotifications } from "reapop";
import { useFetchUser } from "../hooks/auths";
import { useAdmin, useImpersonated, useUser } from "../store/selectors";
import copyToClipboard from "../utils/copyToClipboard";
import AxiosCaller from "../utils/AxiosCaller";

import logo from "../assets/brand/logo-larioht.png";

import {
	AppHeaderDropdown,
} from "./header/index";
import AppBreadcrumb from "./AppBreadcrumb/AppBreadcrumb";
import { SET_SIDEBAR_SHOW } from "../store/constants";

const AppHeader = () => {
	const user = useUser();
	const navigate = useNavigate();
	const [disabled, setDisabled] = useState(false);
	const { fetchUser } = useFetchUser();
	const isImpersonated = useImpersonated();
	const isAdmin = useAdmin();
	const dispatch = useDispatch();
	const { notify } = useNotifications();

	const theme = useSelector(({ base }: { base: IBase }) => base.theme);

	if (theme === "dark") document.body.classList.add("dark-theme");
	else document.body.classList.remove("dark-theme");

	const sidebarShow = useSelector(({ base }: { base: IBase }) => base.sidebarShow);

	const leaveImpersonate = async () => {
		try {
			setDisabled(true);
			await AxiosCaller.delete("/api/user/admin/leaveImpersonate");
			await fetchUser();
			navigate("/");
		} catch (e) {
			console.error(e);
		} finally {
			setDisabled(false);
		}
	};

	return (
		<CHeader className="mb-4" position="sticky">
			<CContainer fluid>
				<CHeaderToggler
					className="ps-1"
					onClick={() => dispatch({ type: SET_SIDEBAR_SHOW, payload: !sidebarShow })}
				>
					<CIcon icon={cilMenu} size="lg" />
				</CHeaderToggler>
				<CHeaderBrand className="mx-auto d-md-none">
					<CIcon height={48} icon={logo} />
				</CHeaderBrand>
				<CHeaderNav className="d-none d-md-flex me-auto">
					{isAdmin
						&& (
							<CNavItem>
								<CNavLink component={NavLink} to="/">
									Utenti
								</CNavLink>
							</CNavItem>
						)}
					<CNavItem>
						<CNavLink component={NavLink} to={isAdmin ? "/applications" : "/"}>
							Bandi
						</CNavLink>
					</CNavItem>
					{isAdmin
						&& (
							<CNavItem>
								<CNavLink component={NavLink} to="/fields">
									Campi
								</CNavLink>
							</CNavItem>
						)}
					{isAdmin
						&& (
							<CNavItem>
								<CNavLink to="/settings">Impostazioni</CNavLink>
							</CNavItem>
						)}
				</CHeaderNav>
				{!isAdmin && user?.spid_type && (
					<div className="ms-3 me-4">
						<span className="spidLabel">Tipo SPID: </span>
						<CButton
							color="primary"
							variant="outline"
							onClick={() => {
								copyToClipboard(user.spid_type ?? "");
								notify("Campo Tipo SPID copiato correttamente", "success");
							}}
						>
							<span className="spidValue">{user.spid_type}</span>
						</CButton>
					</div>
				)}
				{!isAdmin && user?.spid_user && (
					<div className="ms-3 me-4">
						<span className="spidLabel">SPID User: </span>
						<CButton
							color="primary"
							variant="outline"
							onClick={() => {
								copyToClipboard(user.spid_user ?? "");
								notify("Campo SPID User copiato correttamente", "success");
							}}
						>
							<span className="spidValue">{user.spid_user}</span>
						</CButton>
					</div>
				)}
				{!isAdmin && user?.spid_pin && (
					<div className="ms-3 me-4">
						<span className="spidLabel">SPID Pin: </span>
						<CButton
							color="primary"
							variant="outline"
							onClick={() => {
								copyToClipboard(user.spid_pin ?? "");
								notify("Campo SPID Pin copiato correttamente", "success");
							}}
						>
							<span className="spidValue">{user.spid_pin}</span>
						</CButton>
					</div>
				)}
				{!isAdmin && user?.pwd_firma_digitale && (
					<div className="ms-3 me-4">
						<span className="spidLabel">Pin Firma: </span>
						<CButton
							color="primary"
							variant="outline"
							onClick={() => {
								copyToClipboard(user.pwd_firma_digitale ?? "");
								notify("Campo pin firma copiato correttamente", "success");
							}}
						>
							<span className="spidValue">{user.pwd_firma_digitale}</span>
						</CButton>
					</div>
				)}
				{isImpersonated && (
					<div className="ms-3 me-4">
						<CButton
							color="secondary"
							disabled={disabled}
							onClick={() => leaveImpersonate()}
						>
							Torna admin
						</CButton>
					</div>
				)}
				<CHeaderNav className="ms-3 me-4">
					<AppHeaderDropdown />
				</CHeaderNav>
			</CContainer>
			<CHeaderDivider />
			<CContainer fluid>
				<AppBreadcrumb />
			</CContainer>
		</CHeader>
	);
};

export default AppHeader;
