import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../routes";
import { useAuthenticated } from "../../store/selectors";

const UserRoutes = () => {
	const authenticated = useAuthenticated();
	if (!authenticated) return <Navigate to="/login" />;
	return (
		<Routes>
			{routes
				.filter((route) => !route.isAdmin)
				.map(
					(route) => route.element && (
						<Route element={<route.element />} key={route.path} path={route.path} />
					),
				)}
		</Routes>
	);
};

export default memo(UserRoutes);
