/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import {
	matchPath, useLocation, useNavigate, useParams,
} from "react-router-dom";

import { CBreadcrumb, CBreadcrumbItem, CFormInput } from "@coreui/react-pro";
import { useNotifications } from "reapop";
import { useAdmin, useViewSearch } from "../../store/selectors";
import routes, { IRoute } from "../../routes";

import classes from "./AppBreadcrumb.module.scss";

export interface IBreadcrumb {
	pathname: string;
	name: string;
	active: boolean;
}

const AppBreadcrumb = () => {
	const isAdmin = useAdmin();
	const currentLocation = useLocation().pathname;
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState("");
	const { notify } = useNotifications();
	const viewSearch = useViewSearch();
	const searchRef = useRef<any>(null);

	const find = (val: string) => setSearchTerm(val);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (searchTerm) {
				const found = (window as any).find(searchTerm);
				if (!found) {
					notify("Nessun risultato trovato", "warning");
				}
			}
		}, 800);
		return () => clearTimeout(timeoutId);
	}, [notify, searchTerm]);

	const getRouteName = (pathname: string, _routes: IRoute[]) => {
		const currentRoute = isAdmin
			? _routes.find((route) => matchPath({ path: route.path }, pathname))
			: _routes.findLast((route) => matchPath({ path: route.path }, pathname));

		return currentRoute ? currentRoute.name : false;
	};

	const getBreadcrumbs = (location: string) => {
		const breadcrumbs = [] as IBreadcrumb[];
		location.split("/").reduce((prev, curr, index, array) => {
			const currentPathname = `${prev}/${curr}`;
			const routeName = getRouteName(currentPathname, routes);

			if (routeName) {
				breadcrumbs.push({
					pathname: currentPathname,
					name: routeName,
					active: index + 1 === array.length,
				});
			}

			return currentPathname;
		});

		return breadcrumbs;
	};

	const breadcrumbs = getBreadcrumbs(currentLocation);

	return (
		<>
			<CBreadcrumb className="m-0 ms-2">
				<CBreadcrumbItem className={classes.link} onClick={() => navigate("/")}>
					Home
				</CBreadcrumbItem>
				{breadcrumbs.map((breadcrumb) => (
					<CBreadcrumbItem
						className={cx({ [classes.link]: !breadcrumb.active })}
						key={breadcrumb.pathname}
						onClick={() => !breadcrumb.active && navigate(breadcrumb.pathname)}
					>
						{breadcrumb.name}
					</CBreadcrumbItem>
				))}
			</CBreadcrumb>
			{viewSearch && (
				<CFormInput
					disabled={false}
					placeholder="Search"
					ref={searchRef}
					onChange={(e) => find(e.target.value as string)}
				/>
			)}
		</>
	);
};

export default React.memo(AppBreadcrumb);
