/* eslint-disable max-lines */
/* eslint-disable react/no-unstable-nested-components */
import {
	cilPencil, cilPlus, cilTrash, cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CRow,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react-pro";
import {
	memo, useContext, useEffect, useState,
} from "react";
import DataTable from "react-data-table-component";
import { useFetchUser } from "../../../hooks/auths";
import AxiosCaller from "../../../utils/AxiosCaller";
import AddUser from "./AddUser/AddUser";
import { humanize, humanizeValue } from "../../../utils/humanize";
import ToastContext from "../../../layout/Context/ToastContext/ToastContext";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";

const Users = () => {
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [userToEdit, setUserToEdit] = useState({} as any);
	const [users, setUsers] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const { setToastNotification } = useContext(ToastContext);
	const { fetchUser } = useFetchUser();
	const [filterText, setFilterText] = useState("");

	const filteredItems = users.filter(
		(item: any) => (item.alias && item.alias.toLowerCase().includes(filterText.toLowerCase()))
			|| (item.p_iva && item.p_iva.toLowerCase().includes(filterText.toLowerCase())),
	);

	const fetchUsers = async () => {
		try {
			setLoading(true);
			const { data: _users } = (await AxiosCaller.get("/api/user/view", {
				params: {},
			})) as any;
			setUsers(_users);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchUsers();
	}, []);

	const closeModal = () => {
		setVisible(false);
		setUserToEdit({});
		setEditMode(false);
	};

	const onAddUser = () => setVisible(true);

	const closeDeleteModal = () => {
		setIsDeleteModalVisible(false);
		setUserToEdit({});
	};

	const onDeleteConfirm = (user: any) => {
		setUserToEdit(user);
		setIsDeleteModalVisible(true);
	};

	const onDelete = async () => {
		try {
			setLoading(true);
			(await AxiosCaller.delete(`/api/user/${userToEdit?.id}`)) as any;
			setToastNotification({
				isError: false,
				message: "Hai elimminato correttamente l'utente",
			});
			closeDeleteModal();
			await fetchUsers();
		} catch (e) {
			console.error(e);
			setToastNotification({
				isError: true,
				message: "Errore durante la rimozione dell'utente",
			});
		} finally {
			setLoading(false);
		}
	};

	const impersonate = async (user: any) => {
		try {
			setLoading(true);
			await AxiosCaller.get(`/api/user/admin/impersonate/${user.id}`);
			await fetchUser();
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	const getColumns = () => {
		if (!users || !users.length) return [];
		const columns = Object.keys(users[0])
			.filter((field) => field !== "spid_user" && field !== "spid_pin" && field !== "spid_type" && field !== "pwd_firma_digitale")
			.map(
				(user) => ({
					id: user,
					name: humanize(user),
					sortable: true,
					format: (row: any) => humanizeValue(user, row[user]),
					selector: (row: any) => row?.[user],
				} as any),
			);

		columns.push({
			cell: (user: any) => <CIcon icon={cilTrash} onClick={() => onDeleteConfirm(user)} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});

		columns.push({
			cell: (user: any) => (
				<CIcon
					icon={cilPencil}
					onClick={() => {
						setEditMode(true);
						setUserToEdit(user);
						setVisible(true);
					}}
				/>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});

		columns.push({
			cell: (user: any) => <CIcon icon={cilUser} onClick={() => impersonate(user)} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});
		return columns;
	};

	return (
		<>
			<CModal alignment="center" backdrop="static" visible={isDeleteModalVisible} onClose={closeDeleteModal}>
				<CModalHeader>
					<CModalTitle>Modal</CModalTitle>
				</CModalHeader>
				<CModalBody>Sei sicuro di voler eliminare l`utente?</CModalBody>
				<CModalFooter>
					<CButton color="secondary" disabled={loading} onClick={closeDeleteModal}>
						Close
					</CButton>
					<CButton color="primary" disabled={loading} onClick={onDelete}>
						Delete
					</CButton>
				</CModalFooter>
			</CModal>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h4 className="mb-0">Utenti</h4>
							<CButton color="primary" disabled={loading} variant="outline" onClick={onAddUser}>
								<CIcon className="mw-3" icon={cilPlus} />
							</CButton>
						</CCol>
					</CRow>
					<BaseInput
						disabled={false}
						error=""
						id="search-ref"
						initialValue=""
						inputGroupClassName="mb-3"
						setValue={(val: any) => setFilterText(val)}
						stringIcon="Ricerca"
					/>
				</CCardHeader>
				<CCardBody>
					<DataTable
						// className={className}
						columns={getColumns()}
						data={filteredItems}
						// paginationPerPage={rowPerPage}
						// paginationRowsPerPageOptions={[10, 20, 50, 100]}
						// paginationTotalRows={user.length}
						// eslint-disable-next-line react/jsx-sort-props
						onRowClicked={(user: any) => {
							setEditMode(true);
							setUserToEdit(user);
							setVisible(true);
						}}
						progressPending={loading}
						// onChangeRowsPerPage={(perPage: number) => setRowPerPage(perPage)}
						pagination
						pointerOnHover
						// paginationServer
						highlightOnHover
					/>
				</CCardBody>
			</CCard>
			<AddUser
				editMode={editMode}
				initActive={userToEdit?.active}
				initAlias={userToEdit?.alias}
				initPiva={userToEdit?.p_iva}
				initPwdFirmaDigitale={userToEdit?.pwd_firma_digitale}
				initSpidPin={userToEdit?.spid_pin}
				initSpidType={userToEdit?.spid_type}
				initSpidUser={userToEdit?.spid_user}
				refetchUsers={fetchUsers}
				setVisible={closeModal}
				userId={userToEdit?.id}
				visible={visible}
			/>
		</>
	);
};
export default memo(Users);
