import {
	CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
} from "@coreui/react-pro";
import { memo } from "react";

type ModalProps = {
	visible: boolean;
	setVisible: () => void;
	title: string;
	children: React.ReactNode;
	onSuccess?: () => void;
	disabled?: boolean;
};

const Modal = ({
	visible, setVisible, title, children, onSuccess, disabled = false,
}: ModalProps) => (
	<CModal alignment="center" visible={visible} scrollable onClose={setVisible}>
		<CModalHeader>
			<CModalTitle>{title}</CModalTitle>
		</CModalHeader>
		<CModalBody>{children}</CModalBody>
		<CModalFooter>
			<CButton color="secondary" onClick={setVisible}>
					Close
			</CButton>
			<CButton color="primary" disabled={disabled} onClick={onSuccess}>Save changes</CButton>
		</CModalFooter>
	</CModal>
);

export default memo(Modal);
